<template>
  <v-container fill-height>
    <v-row class="justify-center">
      <v-col xs="12" sm="8" md="6" lg="4" xl="3">
        <v-card class="pa-6 rounded-lg" color="accent" elevation="1">
          <v-list-item class="px-0 mb-6">
            <v-list-item-avatar size="48">
              <v-img :src="require('@/assets/logo.png')" />
            </v-list-item-avatar>

            <v-list-item-content>
              <v-list-item-subtitle class="text-body-1">
                Boas vindas,
              </v-list-item-subtitle>

              <v-list-item-title class="text-h6">
                Backoffice LogusPay
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-form v-model="valid" ref="form" autocomplete="off">
            <div @keypress.enter.prevent="submit()">
              <v-text-field
                v-model="form.email"
                label="E-mail"
                backgroundColor="accent"
                outlined
                :rules="rule"
              >
                <template v-slot:append>
                  <v-icon color="secondary"> mdi-email-outline </v-icon>
                </template>
              </v-text-field>

              <v-text-field
                v-model="form.password"
                label="Senha"
                backgroundColor="accent"
                :type="show ? 'text' : 'password'"
                outlined
                :rules="rule"
              >
                <template v-slot:append>
                  <v-icon color="secondary" @click="show = !show">
                    {{ show ? "mdi-eye-outline" : "mdi-eye-off-outline" }}
                  </v-icon>
                </template>
              </v-text-field>

              <v-checkbox
                v-model="remember"
                label="Lembrar acesso"
                class="pt-0 mt-0 mb-2"
              />

              <v-btn color="primary" block large @click="submit()">
                Efetuar Acesso
              </v-btn>
            </div>
          </v-form>
        </v-card>
      </v-col>
    </v-row>

    <loader-hover v-if="loading" />
  </v-container>
</template>

<script>
import { mapActions } from "vuex";
import { signIn } from "@/services/auth";
import { displayAlert } from "@/utils";

export default {
  data() {
    return {
      loading: false,
      valid: false,
      show: false,
      remember: false,
      form: {
        email: "",
        password: "",
      },
    };
  },

  beforeMount() {
    const username = localStorage.getItem("username") || false;

    if (username) {
      this.form.email = username;
      this.remember = true;
    }
  },

  computed: {
    rule() {
      return [(v) => !!v || "Campo Obrigatório"];
    },
  },

  methods: {
    ...mapActions(["setSignIn"]),

    async submit() {
      const _self = this;

      if (!_self.$refs.form.validate()) return;

      window.grecaptcha
        .execute("6LcEGYcjAAAAAKCmD-J0zK_h0eUec8bftsh-UFTL", {
          action: "login",
        })
        .then(async function(token) {
          try {
            _self.loading = true;

            if (_self.remember)
              localStorage.setItem("username", _self.form.email);
            else localStorage.removeItem("username");

            await signIn(_self.form, { gToken: token }).then((res) => {
              _self.setSignIn({
                user: res.body.user,
                token: res.body.token,
              });

              _self.navigation("/main");
            });
          } catch (err) {
            _self.displayAlert(err.data.message, 1);
          } finally {
            _self.loading = false;
          }
        });
    },

    navigation(path) {
      this.$router.push({ path: path });
    },

    displayAlert,
  },
};
</script>

<style></style>
